import React, {Component, useEffect, useState} from "react";
import {Slide} from "react-reveal";
import {Helmet} from "react-helmet";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageWrapper from "../page/PageWrapper";
import Palette from "../../util/Palette";
import {Button, Navbar, Dropdown, Popover, OverlayTrigger} from "react-bootstrap";
import GlobalData from "../../util/GlobalData";
import {Link, useHistory} from "react-router-dom";
import {
    isMobile
} from "react-device-detect";
import {useCookies} from "react-cookie";
import Container from "react-bootstrap/Container";

import {FaHome, FaBook, FaHandsHelping, FaMoon, FaBookmark} from "react-icons/fa";
import {GiJapan} from "react-icons/gi";
import logoKecil from '../../asset/patjarmerah kecil/logo512-removebg-preview.png'
import {
    withRouter
} from 'react-router-dom'
import LanguageSelectionModal from "./LanguageSelectionModal";

function Header(props) {
    let [scrollPosition, setScrollPosition] = useState(0)
    let history = useHistory()
    let user = GlobalData.user
    const [cookie, setCookie, removeCookie] = useCookies()

    const [languageModalShow, setIsLanguageModalShow] = useState(false)

    const isLanguageModalOpen = !cookie.lang || languageModalShow

    const isEN = cookie.lang !== "id";


    function listenToScroll() {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop

        const height =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight

        const scrolled = winScroll / height

        setScrollPosition(scrolled)
    }

    useEffect(() => window.addEventListener('scroll', listenToScroll))
    useEffect(() => {
        return () => {
            window.removeEventListener('scroll', listenToScroll)
        }
    }, []);

    if (isMobile) {
        return <>
            <div style={{
                width: "100%",
                height: "3.5em",
                position: "fixed",
                bottom: 0,
                zIndex: 1000,
                backgroundColor: "white",
                boxShadow: "5px 5px 5px 5px #888888",
                display: "flex",
                flexDirection: "row",
                color: "grey"
            }}>

                <div style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    color: props.activeTab === "home" || props.activeTab === 'en-home' ? Palette.MAROON : null
                }} onClick={() => {
                    if (isEN) {
                        props.history.push("/en/home")
                    } else {
                        props.history.push("/")
                    }
                }}
                >
                    <FaHome style={{
                        fontSize: "1.35em",
                        marginBottom: '0.2em'
                    }}/>
                    <div style={{
                        fontSize: "0.6em"
                    }}>
                        {isEN ? 'Home' : 'Beranda'}
                    </div>
                </div>

                <div style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    color: props.activeTab === "daur" ? Palette.MAROON : null
                }} onClick={() => props.history.push("/daur")}>
                    <img style={{
                        fontSize: "1.35em",
                        marginBottom: '0.2em',
                        width: '1em'
                    }}
                    src={logoKecil}
                    />
                    <div style={{
                        fontSize: "0.6em"
                    }}>
                        Daur
                    </div>
                </div>

                {/*<div style={{*/}
                {/*    flex: 1,*/}
                {/*    display: "flex",*/}
                {/*    flexDirection: "column",*/}
                {/*    alignItems: "center",*/}
                {/*    justifyContent: "center",*/}
                {/*}}>*/}
                {/*    <OverlayTrigger*/}
                {/*        trigger={"click"} placement="top" overlay={popover}>*/}
                {/*        <a style={{*/}
                {/*            flex: 1,*/}
                {/*            display: "flex",*/}
                {/*            flexDirection: "column",*/}
                {/*            alignItems: "center",*/}
                {/*            justifyContent: "center",*/}
                {/*            color: props.activeTab === 'surgaanjingliar-detail' || props.activeTab === 'perjamuan-pulang' || props.activeTab === 'surgaanjingliar' ? Palette.MAROON : '#808080'*/}
                {/*        }} href={"#"} onClick={(e) => e.preventDefault()}>*/}
                {/*            <FaBookmark style={{*/}
                {/*                fontSize: "1.35em",*/}
                {/*                marginTop:'0.1em',*/}
                {/*                marginBottom: '0.3em',*/}
                {/*                width: '0.8em',*/}
                {/*                height: '0.8em',*/}
                {/*            }}/>*/}
                {/*            <div style={{*/}
                {/*                fontSize: "0.6em"*/}
                {/*            }}>*/}
                {/*                patjaristimewa*/}
                {/*            </div>*/}
                {/*        </a>*/}
                {/*    </OverlayTrigger>*/}
                {/*</div>*/}


                {/*<div style={{*/}
                {/*    flex: 1,*/}
                {/*    display: "flex",*/}
                {/*    flexDirection: "column",*/}
                {/*    alignItems: "center",*/}
                {/*    justifyContent: "center",*/}
                {/*    color: props.activeTab === "fba" ? Palette.MAROON : null*/}
                {/*}} onClick={() => props.history.push("/festivalbukuasia")}>*/}
                {/*    <GiJapan style={{*/}
                {/*        fontSize: "1.35em",*/}
                {/*        marginBottom: '0.2em'*/}
                {/*    }}/>*/}
                {/*    <div style={{*/}
                {/*        fontSize: "0.6em"*/}
                {/*    }}>*/}
                {/*        Festival Buku Asia*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </>
    }


    return <Slide bottom when={scrollPosition > 0.9}>
        <div style={{
            width: "100%",
            height: "2em",
            position: "fixed",
            bottom: 0,
            zIndex: 1000,
            display: "flex",
            justifyContent: "center",
            background: Palette.MAROON,
            boxShadow: "0.5px 0.5px 0.5px #888888"
        }}>
            <a style={{
                textAlign: "center",
                width: "100%",
                height: "100%",
                color: "white",
                fontSize: "0.8em",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                Powered By Intellivent
            </a>
        </div>
    </Slide>


    return <>
        <div style={{
            width: "100%",
            boxShadow: "5px 5px 5px 5px #888888",
        }}>
            <Container style={{
                width: "100%",
                zIndex: 1000,
                display: "flex",
                justifyContent: "center",
                background: "white",
                color: "black",
                fontSize: "0.9em",
                paddingTop: "1em"
            }}>
                <Row style={{marginTop: "0.5em", marginBottom: "1em", width: "100%"}}>
                    <Col md={3} style={{display: "flex", flexDirection: "column"}}>
                        <img
                            style={{
                                height: "2.7em",
                                objectFit: "contain",
                                objectPosition: "left"
                            }}
                            src={require("../../asset/patjarmerah.png")}/>

                        <b style={{marginTop: "0.5em"}}>
                            {isEN ? "Main Office" : "Kantor Utama"}
                        </b>
                        <p style={{marginTop: "0.5em"}}>
                            {isEN ? 'Jl. Gedongkuning No.118, Rejowinangun, Kec. Kotagede, \n' +
                                'Kota Yogyakarta, \n' +
                                'Daerah Istimewa Yogyakarta 55171'
                                :
                                'Jl. Gedongkuning No.118, Rejowinangun, Kec. Kotagede, \n' +
                                'Kota Yogyakarta, \n' +
                                'Daerah Istimewa Yogyakarta 55171'
                            }

                        </p>


                    </Col>
                    <Col md={3}>
                    </Col>
                    <Col md={3}>

                    </Col>
                    <Col md={3} style={{display: "flex", flexDirection: "column"}}>
                        <div style={{height: "2.7em"}}/>
                        <b style={{marginTop: "0.5em"}}>
                            {isEN ? 'Support' : 'Bantuan'}
                        </b>

                        <span>
                            <a href={"#"} onClick={() => {
                                setIsLanguageModalShow(true)
                            }}>
                                Ganti Bahasa / Change Language
                            </a>
                        </span>

                        <span>
                            <a href={"#"} onClick={() => {
                            }}>
                                Syarat dan Ketentuan
                            </a>
                        </span>

                        <span>
                            <a href={"#"} onClick={() => {
                            }}>
                                Hubungi Kami
                            </a>
                        </span>


                        {/*<b style={{marginTop: "1em"}}>*/}
                        {/*    {isEN ? 'Contact Us' : 'Hubungi Kami'}*/}
                        {/*</b>*/}
                        {/*<ul style={{paddingLeft: "0.1em"}}>*/}
                        {/*    Instagram :*/}
                        {/*    <a href={"https://www.instagram.com/patjarmerah_id/?hl=en"} onClick={() => {*/}
                        {/*        setIsLanguageModalShow(true)*/}
                        {/*    }}>*/}
                        {/*        @patjarmerah_id*/}
                        {/*    </a>*/}
                        {/*</ul>*/}
                        {/*<ul style={{paddingLeft: "0.1em"}}>*/}
                        {/*    Email :*/}
                        {/*    <a href={"https://www.instagram.com/patjarmerah_id/?hl=en"} onClick={() => {*/}
                        {/*        setIsLanguageModalShow(true)*/}
                        {/*    }}>*/}
                        {/*        renjana@patjarmerah.com*/}
                        {/*    </a>*/}
                        {/*</ul>*/}
                    </Col>
                    <Col md={12} style={{textAlign: "center", paddingTop: "1em"}}>
                        © Copyright 2020 <b>patjarmerah</b>, powered By <b
                        style={{fontFamily: "Spartan", fontWeight: "900"}}>INTELLIVENT</b>
                    </Col>
                </Row>
            </Container>

        </div>

        <LanguageSelectionModal
            show={isLanguageModalOpen}
            hide={() => setIsLanguageModalShow(false)}
        />

    </>


}

export default withRouter(Header)
